<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <titlesectionList :title="title" desc1='View all of your orders below, or use the filters to refine your search.' />
                <h6 class="mb-3">Filters</h6>
                <ul class="filter-bar">
                    <li v-for="filter in availableFilters" :key="filter.id" class="nav-item nav-icon dropdown"
                        v-nav-toggle>
                        <a href="#" class="filter-btn btn border add-btn dropdown-toggle shadow-none"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="las la-plus mr-2"></i>
                            {{ filter.name }}
                        </a>
                        <FilterCustomerEmail :name="filter.name" :filter-id="filter.id"
                            :is-multi-select="filter.isMultiSelect" />
                    </li>
                </ul>
                <div class="d-flex active-filters">
                <div v-for="active in activeFilters" :key="active.id">
                <div>
                    <b-button v-if="active.isActive" @click="removeFilter(active.id)" variant="outline-success"
                        class="mb-1">
                        {{ active.name }}: {{ active.filterValue }}<b-badge variant="success" class="ml-2">X</b-badge>
                    </b-button>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-12">
                <OrderList v-for="Purchase in orders" :key="Purchase.increment_id"
                    :currency='Purchase.order_currency_code' :number='Purchase.increment_id' :status='Purchase.status' :date="Purchase.created_at" :customer-name="Purchase.customer_firstname + ' ' + Purchase.customer_lastname" :total="Purchase.grand_total" :link="Purchase.entity_id" :entityNo="Purchase.entity_id"/>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import dateFormat from "dateformat";
import FilterCustomerEmail from '../../../components/omni/filters/orders/customer-email.vue'
import OrderList from '../../../components/omni/orders/modals/order-list.vue'
export default {
    name: 'AllOrders',
    components: { FilterCustomerEmail, OrderList },
    beforeMount() {
        this.$store.dispatch('fetchOrders');
        this.selectedDate = ''
    },
    computed: {
        orders() {
            return this.$store.state.orders.orders
        },
        startDate() {
            let startDate = this.selectedDate[0]
            return dateFormat(startDate, "isoDate")
        },
        endDate() {
            let startDate = this.selectedDate[1]
            return dateFormat(startDate, "isoDate")
        },
        availableFilters() {
            return this.$store.getters['orderFilters/getFilters'];
        },
        activeFilters() {
            return this.$store.getters['orderFilters/getFilters'];
        },
    },
    methods: {
        removeFilter(filterId) {
            this.$store.commit('orderFilters/setFilter', { id: filterId, status: false, filterValue: '' });
            this.$store.dispatch('fetchOrders');
        }
    },
    watch: {
        startDate(newValue) {
            this.$store.dispatch('fetchOrders', { startDate: newValue });
        }
    },
    data() {
        return {
            selectedDate: [
                new Date()],
            title: "Orders",
            desc1: "A purchase dashboard enables purchasing manager to efficiently track, evaluate,",
            desc2: "and optimize all acquisition processes within a company.",
            link: { name: 'purchase.addpurchase' },
            linktext: "Add Purchase"
        }
    }
}
</script>
<style lang="scss">
.filter-bar {
    display: flex;
    gap: 15px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    background: #f4f5fa;
    border-radius: 8px;
    padding: 20px;

    .filter-btn {
        background-color: #fff !important;
    }
}
.active-filters{
    margin-bottom: 15px;
    gap: 15px;
}
</style>