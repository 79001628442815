<template>
    <RouterLink :to="orderlink(link)">
       <div class="card invoice-card">
          <div class="invoice-card__item">
            <div class="icon"><invoiceIcon /></div>
            <span>{{ customerName }}</span>
            <p>Order #{{number}}</p>
            </div>  
            <div class="invoice-card__item">
            <p>Date: {{date}}</p>
            </div> 
            <div class="invoice-card__item">
            <p>{{currency}} {{parseFloat(total).toFixed(2) }}</p>
            </div> 
            <div class="invoice-card__item">
               <order-status :status="status"/>
            </div>  
        </div>
    </RouterLink>
      </template>
      <script>
    import { RouterLink } from 'vue-router'
    import invoiceIcon from '../../icons/invoice.vue'
    import orderStatus from '../../orders/modals/status.vue'
    var CryptoJS = require("crypto-js");
      export default {
        name: "OrderList",
        components: { RouterLink, invoiceIcon, orderStatus },
        props: {
            entityNo: { type: String, default: "" },
            number: { type: String, default: "" },
            customerName: { type: String, default: "" },
            status: { type: String, default: "" },
            date: { type: String, default: "" },
            link: { type: String, default: "" },
            currency: { type: String, default: "" },
            total: { type: String, default: "" }
        },
        methods: {
      orderlink(entityId) {
            return '/order/' + entityId + '/' + CryptoJS.SHA256(this.number + this.link, "OmniAutomotive@777").toString(CryptoJS.enc.Hex) + '/' + this.number + '/overview'
        }
    },
        computed: {
            hasHeaderTitleSlot() {
                return !!this.$slots.headerTitle;
            },
            hasHeaderActionSlot() {
                return !!this.$slots.headerAction;
            },
            hasBodySlot() {
                return !!this.$slots.body;
            },
            hasFooterSlot() {
                return !!this.$slots.footer;
            }
        }
    }
      </script>
      <style scoped lang="scss">
      .invoice-card{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        padding: 20px;
        &__item{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
        color: #03102A;
        p{
            margin: 0;
        }
        &:first-child{
        justify-content: flex-start;
        }
        .icon{
          background: #03102A;
          width: 50px;
          height: 50px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          svg{
            width: 30px;
            height: 30px;
            fill: #fff !important;
          }
        }
        }
        &:hover {
            background: #f3f6f9;
        }
      }
    </style>